<script setup lang="ts">
import axios from 'axios'
import { onMounted, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import InvoicesList from './InvoicesList.vue'
import { useAuthStore } from '@/@core/stores/auth'
import type { Contract } from '@/@core/stores/contract'
import { useContractStore } from '@/@core/stores/contract'
import API_ENDPOINTS from '@/api/client'
import { track } from '@/boot/mixpanel'

definePage({
  meta: {
    authenticatedOnly: true,
  },
})

const { t } = useI18n()
const contracts = ref<Contract[]>([])
const auth = JSON.parse(localStorage.getItem('auth') ?? '{}')
const accessToken = auth?.access_token || ''
const isLoading = ref(true)
const sortBy = [{ key: 'contract_id', order: 'desc' } as const]
const loadingMessage = ref('')
const contractStore = useContractStore()
const authStore = useAuthStore()

const filterType = ref<'PENDING' | 'COMPLETED' | 'CANCELLED'>('PENDING')

watch(filterType, newFilter => {
  if (newFilter === 'COMPLETED')
    contracts.value = contractStore.completedContracts

  else if (newFilter === 'CANCELLED')
    contracts.value = contractStore.cancelledContracts

  else if (newFilter === 'PENDING')
    contracts.value = contractStore.pendingContracts

  track('Contract filter changed', {
    filter: newFilter,
    user: authStore.user,
  })
})

const getContracts = async () => {
  loadingMessage.value = t('Loading contracts...')
  isLoading.value = true

  try {
    await contractStore.fetchAllContracts(authStore.user.id, authStore.access_token)
    contracts.value = contractStore.pendingContracts
  }
  catch (error) {
    console.error('Failed to fetch contracts:', error)
  }
  finally {
    loadingMessage.value = ''
    isLoading.value = false
  }
}

const downloadContract = async (id: string, contract_id: string) => {
  loadingMessage.value = t('Downloading contract...')
  isLoading.value = true
  try {
    const url = `${API_ENDPOINTS.DOWNLOAD_CONTRACT}${id}`

    const res = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      responseType: 'blob',
    })

    const blob = new Blob([res.data], { type: 'application/pdf' })
    const downloadUrl = window.URL.createObjectURL(blob)
    const link = document.createElement('a')

    link.href = downloadUrl
    link.setAttribute('download', `${contract_id}.pdf`)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }
  catch (error) {
    console.error('Error downloading the contract:', error)
  }
  finally {
    loadingMessage.value = ''
    isLoading.value = false
    track('Downloaded contract', {
      contract_id,
    })
  }
}

onMounted(() => {
  getContracts()
})
</script>

<template>
  <InvoicesList
    v-model:filter-type="filterType"
    v-model:sort-by="sortBy"
    :contracts="contracts"
    :is-loading="isLoading"
    :loading-message="loadingMessage"
    @download-button-clicker="downloadContract"
  />
</template>
